<template>
  <div
    v-if="order"
    class="add-new-item-admin"
  >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.orderCreate')"
      :title="$t('messages.orderCreate')"
      @submitPressed="submitPressed"
    />
    <order-form :order-obj="order" :action="action" type="create" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';

export default {
  components: {
    TitleBar,
    OrderForm
  },
  data() {
    return {
      action: null,
      order: {
        statusSelect: {
            id: 'NEW',
            label: this.$helper.getEnumTranslation("ORDER_STATUS", "NEW", this.$i18n.locale),
        }
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
};
</script>

<style lang="scss">
</style>
